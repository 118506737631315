html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color:  #ecf0f1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

.contacto-container{
  background-color: #fff; /* Fondo blanco o ajusta el color */
  padding: 20px; /* Espaciado interno */
  padding-top: 5px;
  border-radius: 8px; /* Bordes redondeados */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra */
  color: #a93226; /* Color del texto para que combine con tu diseño */
  max-width: 270px; /* Ancho máximo para que no sea demasiado grande */
  text-align: left; /* Alineación del texto */
}

/* Header */

.header {
  position: fixed; /* Hace que el header sea fijo en la parte superior */
  top: 0; /* Alineado en la parte superior de la página */
  left: 0;
  width: 100%; /* Toma el ancho completo de la página */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px -1px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Asegura que el header esté encima del resto del contenido */
}

.header__logo img {
  max-width: 80px;
  margin-left: 15px;
}

.header__nav ul {
  list-style: none;
  display: flex;
  gap: 30px;
  margin: 0;
  padding: 0;
}

.header__nav a {
  color:  #a93226;
  text-decoration: none;
  font-weight: 500;
  font-size: 22px;
  transition: color 0.3s ease;
}

.header__nav a:hover {
  color:  #17202a;
}

.header__cta .cta-button {
  display: flex;
  align-items: center;
  padding: 8px 10px;
  background-color:  #28b463;
  margin-right: 20px;
  color: #fff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.header__cta .cta-button:hover {
  background-color:  #239b56;
}

/* Imagen Fondo */

.background-section {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh; /* Ajusta la altura de la sección */
  display: flex;
  align-items: center;
  color: white; /* Cambia el color del texto si necesitas contraste */
  padding: 20px;
}

.image-container{
  align-self: flex-start; /* Alinea la imagen en la parte superior */
  margin-top: -35px; /* Ajusta la distancia según sea necesario */
}

.text-container {
  max-width: 100%; /* Limita el ancho del texto */
  margin: 0 auto; /* Centra el texto horizontalmente */
  padding: 20px; /* Espacio alrededor del texto */
  color: white; /* Asegúrate de que el color del texto sea visible */
  line-height: 1.4; /* Mejora la legibilidad */
  text-align: justify; /* Justifica el texto */
  font-size: 17px;
}

@media (max-width: 768px) {
  .text-container {
    max-width: 90%; /* Aumenta el ancho para pantallas pequeñas */
    padding: 15px;
  }
}

.text-container h1, text-container p{
  margin: 10px 0;
}

.logo{
  max-width: 80%;
  height: auto;
}

/* Imagenes */
.image-gallery-container {
  max-width: 100%;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden; /* Oculta cualquier desbordamiento */
}

.main-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
}


.logo-container {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.gallery-container {
  flex: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-end;
}

.image-gallery img {
  width: 150px; /* Ajusta el ancho según sea necesario */
  height: auto;
  border-radius: 8px;
  object-fit: cover;
}

@media (max-width: 768px) {
  .image-gallery img {
    width: 100px; /* Ajusta el tamaño para pantallas pequeñas */
  }
}


/* Estilo para el modal */
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8); /* Fondo oscuro con opacidad */
  z-index: 1000;
}

.modal-content {
  max-width: 80%;
  max-height: 80%;
  border-radius: 8px;
}

.close {
  position: absolute;
  top: 20px;
  right: 30px;
  color: white;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
}

/*Footer*/

.footer {
  background-color: #a93226;
  color: white;
  padding: 20px;
  text-align: center;
  margin-top: 50px;
}

.footer__content {
  max-width: 1200px;
  margin: 0 auto;
}

.footer__content a {
  color: white; /* Cambia el color del enlace a blanco */
  text-decoration: none; /* Remueve el subrayado */
  font-weight: bold;
}

.footer__content a:hover {
  text-decoration: underline; /* Subrayado al pasar el cursor */
}

/* Servicios */
.servicios {
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
  color: white;
  font-size: 20px;
}

.servicios h1 {
  margin-bottom: 20px;
}

.servicios-contenido {
  display: flex;
  gap: 20px; /* Espacio entre las columnas */
}

.servicios__lista, .que-ofrecemos {
  flex: 1; /* Cada columna ocupará el mismo ancho */
  text-align: left;
}

.que-ofrecemos h2 {
  color: white;
}

.que-ofrecemos ul {
  list-style-type: disc;
  margin-left: 20px;
}

.que-ofrecemos li {
  color: white;
  margin-bottom: 5px;
}

.servicio {
  background-color: white;
  color: #333;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.two-columns {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Espacio entre columnas y elementos */
}

.two-columns ul {
  flex: 1 1 50%; /* Cada columna ocupa el 50% del ancho */
}


/* Clientes */

.clientes {
  padding: 20px;
  background-color: #f9f9f9; /* Cambia el color de fondo si deseas */
  text-align: center;
}

.clientes h1 {
  color: #a93226; /* Usa el color principal de tu sitio */
  margin-bottom: 20px;
}

.clientes p {
  color: #000000; /* Usa el color principal de tu sitio */
}

.clientes__lista {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.cliente {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  width: 200px;
  height: 200px; /* Ajusta la altura para centrar mejor las imágenes verticalmente */
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  text-align: center;
}

.cliente h3 {
  color: #333;
  margin-bottom: 10px;
}

.cliente p {
  color: #666;
  font-size: 14px;
}

.cliente__imagen {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Asegura que la imagen se ajuste sin deformarse */
}

/*Impresiones*/
.impresiones3D {
  padding: -5px;
}

.impresiones3D h1 {
  color: #fff;
  text-align: center;
}

.impresiones3D__contenido {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.impresiones3D__texto {
  flex: 1;
  text-align: left;
  max-width: 950px;
  padding-left: 40PX;
  font-size: 21px;
}

.impresiones3D__imagen img {
  width: 100%;
  max-width: 300px;
  border-radius: 10px;
  margin-right: 110px;
}

/* Mobile */
/* Adjust header and menu for mobile */
@media (max-width: 768px) {
  .header__nav ul {
    flex-direction: column;
    gap: 5px;
    padding: 10px;
  }

  .header__cta .cta-button {
    width: 100%;
    margin: 0;
    padding: 15px;
  }

  .header__logo img {
    max-width: 60px;
  }
}

/* Make the background and content sections stack vertically */
@media (max-width: 768px) {
  .background-section {
    flex-direction: column;
    padding: 20px;
  }

  .text-container {
    max-width: 100%;
    padding: 10px;
    font-size: 14px;
  }

  .image-container {
    align-self: center;
    margin-top: 20px;
  }
}

/* Adjust the 3D Printing Section for mobile */
@media (max-width: 768px) {
  .impresiones3D__contenido {
    flex-direction: column;
    align-items: center;
  }

  .impresiones3D__texto {
    text-align: center;
    max-width: 100%;
    padding: 10px;
  }

  .impresiones3D__imagen img {
    margin-right: 0;
    max-width: 80%;
  }
}

/* Gallery adjustments for mobile */
@media (max-width: 768px) {
  .image-gallery img {
    width: 80px;
    height: 80px;
  }
}

/* Footer fixed at the bottom and responsive */
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

@media (max-width: 768px) {
  .footer {
    padding: 10px;
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .cta-button {
      font-size: 0; /* Oculta el texto del botón en pantallas móviles */
      padding: 10px; /* Ajusta el espacio del botón */
  }
  .cta-button .whatsapp-icon {
      font-size: 24px; /* Ajusta el tamaño del icono en móvil */
  }
}

@media (max-width: 768px) {
  .two-columns {
    flex-direction: column; /* Cambia a columna en móviles */
  }

  .two-columns ul {
    flex: 1 1 100%; /* Cada columna ocupa el 100% del ancho en móvil */
  }
}
@media (max-width: 768px) {
  .main-container {
    flex-direction: column;
    align-items: center;
  }

  .gallery-container {
    align-items: center;
  }
}